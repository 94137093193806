// import jquery
import $ from "jquery";

const fetchVideos = () =>
  $.ajax({
    url: "/api/list",
    type: "GET",
    success: function (data) {
      let html = "";
      data.forEach(({ created, message, id, star, videos }) => {
        html += `
                <li id="video-${id}">
                    <p>${star} says ${message}</p>
                    <p><small> Created ${new Date(created)} id=${id}</small></p>
                    ${videos
                      .map(
                        video =>
                          `<video src="${video.url}" controls></video><p>${video.description}</p>`
                      )
                      .join("")}
                    <form action="/api/generate" method="GET">
                        <input type="hidden" name="id" value="${id}">
                        <input type="hidden" name="created" value="${created}">
                        <input type="hidden" name="star" value="${star}">
                        <input type="hidden" name="message" value="${message}">
                        <input type="submit" value="Generate video"> 
                    </form>                                        
                    <form action="/api/remove" method="GET">
                        <input type="hidden" name="id" value="${id}">
                        <input type="hidden" name="created" value="${created}">
                        <input type="submit" value="Delete">
                    </form>
                </li>
            `;
      });
      $("#videos").html(html);
    }
  });

const fetchRequests = () => {
  // get the ids from localStorage
  const ids = JSON.parse(localStorage.getItem("request_ids") || "[]").reverse();
  // remove the contents of #requests
  $("#requests").html("");
  // append the divs to the #requests element
  // and call fetchRequest for appended div

  ids.forEach(id => {
    $("#requests").append(
      `<div data-id="${id}">Loading ${id}</div><a href="/request/?id=${id}">View</a>`
    );
    fetchRequest($(`div[data-id="${id}"]`));
  });
};

const fetchRequest = $el =>
  $.ajax({
    url: "/api/video-request?id=" + $el.attr("data-id"),
    type: "GET",
    error: function (err) {
      $el.html(`
            <p>There was an error fetching request with id ${$el.attr(
              "data-id"
            )}
            : ${err.responseText}</p>
        `);
    },
    success: function (data) {
      console.log(data);

      if (!data.generations) {
        return setTimeout(() => fetchRequest($el), 1000);
      }

      const generations = Object.keys(data.generations).map(
        generation_id => data.generations[generation_id]
      );

      generations.sort((a, b) => b.initiated - a.initiated);
      // most recent generation
      const { watermarked_video_url, status_history } = generations[0];

      if (!watermarked_video_url) {
        setTimeout(() => fetchRequest($el), 5000);
      }

      $el.html(`
        <p>
            ${
              watermarked_video_url
                ? `<video src="${watermarked_video_url}" controls></video>`
                : `${status_history
                    .map(
                      ({ datestamp, status }) => `${datestamp} - ${status}<br>`
                    )
                    .join("")}
                <br>
                `
            }
            <button 
                      onclick="const ids = window.localStorage.getItem('request_ids') || '[]';window.localStorage.setItem('request_ids', JSON.stringify(JSON.parse(ids).filter(id => id !== '${
                        data.id
                      }'))); window.location = '/requests/';">Delete</button>
            </p>
        `);
    }
  });

const fetchDashboard = () => {};

const handleVideoRequest = () => {
  $(document).on("submit", "form#request-video", function (e) {
    const form = $(this);

    const data = form.serialize();

    form.hide();

    const status = $(`<p>loading...</p>`);

    form.after(status);

    $.ajax({
      url: form.attr("action"),
      type: form.attr("method"),
      data,
      success: function ({ id }) {
        status.html(`<p>Video is being generated...</p>`);
        // add the id to an array in localStorage so we can check on it later
        const ids = JSON.parse(localStorage.getItem("request_ids") || "[]");
        ids.push(id);
        localStorage.setItem("request_ids", JSON.stringify(ids));
        window.location = "/request/?id=" + id;
      },
      error: function (err) {
        status.html(`<pre>${err.responseText}</pre>`);
        form.show();
      }
    });

    e.preventDefault();
    return false;
  });
};

$(document).ready(function () {
  // if the #videos element exists, then fetch the videos
  if ($("#videos").length) fetchVideos();

  if ($("#request").length) {
    const id = new URLSearchParams(window.location.search).get("id");
    $("#request").attr("data-id", id);
    fetchRequest($("#request"));
  }

  if ($("#requests").length) fetchRequests();

  if ($("#dashboard").length) fetchDashboard();

  if ($("#request-video").length) handleVideoRequest();
});
